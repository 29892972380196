// # A library of AMS API Methods

// Imports
var _ = require('lodash');
var Moment = require('moment');

// Exports
module.exports.getEvents = getEvents;
module.exports.searchKeyUp = searchKeyUp;
module.exports.searchOnEnter = searchOnEnter;
module.exports.getCouncils = getCouncils;
module.exports.getCommittees = getCommittees;
module.exports.getOrganizations = getOrganizations;
module.exports.getOrganizationsByIndividual = getOrganizationsByIndividual;
module.exports.getEventRegistrants = getEventRegistrants;
module.exports.getCredentialedIndividuals = getCredentialedIndividuals;
module.exports.addOrganizationBox = addOrganizationBox;
module.exports.onChangeSearchType = onChangeSearchType;
module.exports.searchOrganizations = searchOrganizations;
module.exports.searchOrganizationsByIndividual = searchOrganizationsByIndividual;
module.exports.addToCart = addToCart;
module.exports.removeFromCart = removeFromCart;
module.exports.removeFromGuestCart = removeFromGuestCart;
module.exports.enableOption = enableOption;
module.exports.toggleEventOption = toggleEventOption;
module.exports.saveReturnUrl = saveReturnUrl;
module.exports.openPaymentForm = openPaymentForm;
module.exports.validateRegistration = validateRegistration;
module.exports.submitRegistration = submitRegistration;
//module.exports.savePaymentForm = savePaymentForm;
module.exports.sendReceipt = sendReceipt;
module.exports.addGuest = addGuest;
module.exports.selectGuest = selectGuest;
module.exports.removeGuest = removeGuest;
module.exports.resetGuestForm = resetGuestForm;
module.exports.addToGuest = addToGuest;
module.exports.clearCart = clearCart;
module.exports.backToEvent = backToEvent;
module.exports.saveProfile = saveProfile;
module.exports.resetProfile = resetProfile;
module.exports.saveBusiness = saveBusiness;
module.exports.addRep = addRep;
module.exports.resetBusinessProfile = resetBusinessProfile;
module.exports.downloadMinuteReport = downloadMinuteReport;
module.exports.ajaxRequest = 0

// Constants
const perPage = 15;


function getOrganizations(page = 0, historyChange = false) {
	let container = $('#business-container');
	let searchType = $('select[name="basedon"]').val().trim();
	let keyword = $('input[name="keyword"]').val().trim().toLowerCase();
	let supportRegionCode = null;

	if (Cubic.ams.ajaxRequest != 0) return;
    Cubic.ams.ajaxRequest = 1;
    toggleLoadBar(container, "Loading directory");

    let queryParams = {
		$top: perPage,
		$skip: page * perPage,
		$orderby: 'OrganizationName',
		$count: true
    }

    switch (searchType) {
    	case "Keyword":
			queryParams.$filter = `(contains(OrganizationName, '${keyword}') or contains(Description, '${keyword}') or contains(PreferredName, '${keyword}'))`;
			break;
		case "Name":
			queryParams.$filter = `contains(OrganizationName, '${keyword}')`;
			break;
		case "Individual":
			getOrganizationsByIndividual();
			return;
		case "FENBC":
			supportRegionCode = 'F';
			break;
		case "Southeast":
			supportRegionCode = 'S';
			break;
		case "Western":
			supportRegionCode = 'W';
			break;
		case "NEW":
			queryParams.$filter = "YearJoined gt " + String(new Date().getFullYear() - 2);
			$('input[name="keyword"]').val('');
			break;
		case "All":
			$('input[name="keyword"]').val('');
			break;
		default:
			$('input[name="keyword"]').val('');
    }

    if (supportRegionCode) {
		queryParams.$filter = `(contains(SupportRegionCode, '${supportRegionCode}') or contains(SupportRegionCode,'AL'))`;

		if (keyword) {
			queryParams.$filter += ` and (contains(OrganizationName, '${keyword}') or contains(Description, '${keyword}') or contains(PreferredName, '${keyword}'))`;
		}
    }

    if(!historyChange) {
    	let search = new URLSearchParams(window.location.search);
        if (supportRegionCode) {
			if (search.has('region')) search.set('region', searchType);
			else search.append('region', searchType);
		}
		else {
			if (search.has('region')) search.delete('region');
		}

        if (keyword) {
        	if (search.has('keyword')) search.set('keyword', keyword);
        	else search.append('keyword', keyword);
        }
        else {
			if (search.has('keyword')) search.delete('keyword');
        }

		if (search.has('page')) search.set('page', page);
    	else search.append('page', page);

        history.pushState(null, '', "?" + search.toString());
    }

	$.ajax({
		type: 'GET',
		url: Cubic.urls.AMS_PUBLIC_API + '/Organizations',
		data: queryParams,
		success: function (data, textStatus, jqXHR) {

			container.empty().removeClass('text-center');
			$('input[name="keyword"], select[name="basedon"]').attr('disabled', false);

			if (data.hasOwnProperty('Items')) {
				data.Items.forEach(function(organization) {
					addOrganizationBox(organization);
				});

				if (data.Items.length == 0) {
					container.append('<h3>No members found.</h3>');
				}
			}

			new window.Cubic.Masonry('#business-container', {
				columnWidth: '.item',
				itemSelector: '.item'
			});

			let pagination = {
				NumRecordsFound: data.Count,
				PageNum: parseInt(page) + 1,
				TotalPages: Math.ceil(data.Count / perPage),
			};

			console.log("Pagination", pagination);

			setPagination(pagination, parseInt(page) + 1);
			setPagesInfo(pagination, data.Items);

			Cubic.ams.ajaxRequest = 0;
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("getOrganizations Error!", errorThrown);

			Cubic.ams.ajaxRequest = 0;
			
			if (jqXHR.status == 429) {
				setTimeout(getOrganizations(page), 750);
			}
		},
		dataType: 'JSON'
	});

}

function getOrganizationsByIndividual() {
	let container = $('#business-container');
	let memberName = $('input[name="membername"]').val().trim();

	if (!memberName) return;

	$.ajax({
		type: 'GET',
		url: Cubic.urls.AMS_PUBLIC_API + `/Organizations/GetByIndividualName/${memberName}`,
		success: function (data, textStatus, jqXHR) {

			container.empty().removeClass('text-center');

			console.log("GOT BUSINESSES", data);

			data.forEach(function(organization) {
				addOrganizationBox(organization);
			});

			if (data.length == 0) {
				container.append('<h3>No members found.</h3>');
			}

			new window.Cubic.Masonry('#business-container', {
				columnWidth: '.item',
				itemSelector: '.item'
			});

			$('.pages, .pages-info').empty();

			// let pagination = {
			// 	NumRecordsFound: data.Count,
			// 	PageNum: parseInt(page) + 1,
			// 	TotalPages: Math.ceil(data.Count / perPage),
			// };

			// console.log("Pagination", pagination);

			// setPagination(pagination, parseInt(page) + 1);
			// setPagesInfo(pagination, data.Items);

			Cubic.ams.ajaxRequest = 0;
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("getOrganizations Error!", errorThrown);

			Cubic.ams.ajaxRequest = 0;
			
			if (jqXHR.status == 429) {
				setTimeout(getOrganizations(page), 750);
			}
		},
		dataType: 'JSON'
	});
}

function getEvents(page = 0, category) {

	let container = $('#events-container');

	if (Cubic.ams.ajaxRequest != 0) return;
    Cubic.ams.ajaxRequest = 1;
    toggleLoadBar(container, "Loading events");

    let queryParams = {
		$top: perPage,
		$skip: page * perPage,
		$orderby: 'StartDate desc',
		$count: true
    }

    if (category) {
		queryParams.$filter = `contains(Category, '${category}')`;
    }

    console.log("CATEGORY", category);

    $.ajax({
		type: 'GET',
		url: Cubic.urls.AMS_PUBLIC_API + '/Events',
		data: queryParams,
		success: function (data, textStatus, jqXHR) {

			container.empty().removeClass('text-center');
			console.log("Event data response", data)
			if (data.hasOwnProperty('Items')) {
				data.Items.forEach(function(event) {
					addEventRow(event);
				});

				if (data.Items.length == 0) {
					container.append('<h3>No current events could be found matching the specified search.</h3>');
				}
			}

			let pagination = {
				NumRecordsFound: data.Count,
				PageNum: page + 1,
				TotalPages: Math.floor(data.Count / perPage),
			};

			console.log("Pagination", pagination);

			setPagination(pagination, page + 1);
			setPagesInfo(pagination, data.Items);

			Cubic.ams.ajaxRequest = 0;
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("getEvents Error!", errorThrown);

			Cubic.ams.ajaxRequest = 0;
			
			if (jqXHR.status == 429) {
				setTimeout(getEvents(page), 750);
			}
		},
		dataType: 'JSON'
	});
}

function getCommittees(page = 0) {
	let container = $('#all-committees');
	let keyword = $('input[name="filterKeyword"]').val().trim().toLowerCase();

	if (Cubic.ams.ajaxRequest != 0) return;
    Cubic.ams.ajaxRequest = 1;
    toggleLoadBar(container, "Loading committees");

	let orderBy = 'Description';
    let orderByValue = $('#orderBy').val();
    if (orderByValue == 'date') orderBy = 'DateFormed';

    let sortOrder = encodeURI($('#sort').val());

    let queryParams = {
		$top: perPage,
		$skip: page * perPage,
		$orderby: orderBy + ' ' + sortOrder,
		$count: true
    }

    if (keyword) {
		queryParams.$filter = "(contains(Description,'" + keyword + "'))";
    }

    $.ajax({
		type: 'GET',
		url: Cubic.urls.AMS_PUBLIC_API + '/Groups',
		data: queryParams,
		success: function (data, textStatus, jqXHR) {

			container.empty().removeClass('text-center');
			console.log("Committees results!", data)
			if (data.hasOwnProperty('Items')) {
				data.Items.forEach(function(committee) {
					addCommitteeBox(committee);
				});

				if (data.Items.length == 0) {
					container.append('<h3>No committees could be found matching the specified search.</h3>');
				}
			}

			let pagination = {
				NumRecordsFound: data.Count,
				PageNum: page + 1,
				TotalPages: Math.floor(data.Count / perPage),
			};

			console.log("Pagination", pagination);

			setCommitteePagination(pagination, page + 1);
			setPagesInfo(pagination, data.Items);

			Cubic.ams.ajaxRequest = 0;
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("getCommittees Error!", errorThrown);

			Cubic.ams.ajaxRequest = 0;
			
			if (jqXHR.status == 429) {
				setTimeout(getCommittees(page), 750);
			}
		},
		dataType: 'JSON'
	});
}

function getCouncils() {
	let container = $('#councils');

    let queryParams = {
		$top: 100,
		$skip: 0,
		$count: true,
		$orderby: 'Description asc',
		$filter: "(contains(GroupType,'Council'))",
    }

    $.ajax({
		type: 'GET',
		url: Cubic.urls.AMS_PUBLIC_API + '/Groups',
		data: queryParams,
		success: function (data, textStatus, jqXHR) {

			//container.empty().removeClass('text-center');
			console.log("Councils results!", data)
			if (data.hasOwnProperty('Items')) {
				data.Items.forEach(function(council) {
					addCouncilBox(council);
				});

				if (data.Items.length == 0) {
					container.append('<h3>No councils could be found.</h3>');
				}
			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("getCommittees Error!", errorThrown);

			Cubic.ams.ajaxRequest = 0;
			
			if (jqXHR.status == 429) {
				setTimeout(getCommittees(page), 750);
			}
		},
		dataType: 'JSON'
	});
}

function getEventRegistrants(page = 0, eventId) {
	let container = $('#registrants');

	var perPageRegistrants = 100;

    let queryParams = {
    	eventId: eventId,
		$top: perPageRegistrants,
		$skip: page * perPageRegistrants,
		$count: true,
		$orderby: 'LastName asc',
    }

    $.ajax({
		type: 'GET',
		url: Cubic.urls.AMS_PUBLIC_API + '/EventRegistrants',
		data: queryParams,
		success: function (data, textStatus, jqXHR) {

			//container.empty().removeClass('text-center');
			console.log("Event registrants results!", data)
			if (data.hasOwnProperty('Items')) {
				data.Items.forEach(function(registrant) {
					addRegistrantBox(registrant);
				});

				let pagination = {
					NumRecordsFound: data.Count,
					PageNum: page + 1,
					TotalPages: Math.ceil(data.Count / perPageRegistrants),
					PerPage: perPageRegistrants
				};

				let morePages = data.Count && page + 1 != pagination.TotalPages;
				if (morePages) {
					let loadMoreFn = function() {
						let windowHeight = window.scrollY + window.outerHeight;
						let registrantsBoxHeight = $('#registrants').offset().top + $('#registrants').height();

						if (windowHeight >= registrantsBoxHeight) {
							getEventRegistrants(page + 1, eventId);
							$(window).off('scroll', '', loadMoreFn);
						}
					}
					$(window).on('scroll', loadMoreFn);
				}
			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("getEventRegistrants Error!", errorThrown);

			Cubic.ams.ajaxRequest = 0;
			
			if (jqXHR.status == 429) {
				setTimeout(getEventRegistrants(page, eventId), 750);
			}
		},
		dataType: 'JSON'
	});
}

function getCredentialedIndividuals(page = 0, accrediation = 'fm') {
	let container = $('#credentialed-individuals');

	var perPageIndividuals = 100;

	let queryParams = {
		$top: perPageIndividuals,
		$skip: page * perPageIndividuals,
		$count: true,
		$orderby: 'LastName asc, FirstName asc, MiddleInit asc',
    }

    if (accrediation == "fm") {
		queryParams.$filter = "(contains(CredentialCode,'FMPC'))";
    }
    else {
		queryParams.$filter = "(contains(CredentialCode,'FAPC'))";
    }

    if (Cubic.ams.ajaxRequest != 0) return;
    Cubic.ams.ajaxRequest = 1;
    toggleLoadBar(container, "Loading credentialed individuals");

    $.ajax({
		type: 'GET',
		url: Cubic.urls.AMS_PUBLIC_API + '/CredentialedIndividuals',
		data: queryParams,
		success: function (data, textStatus, jqXHR) {

			container.empty().removeClass('text-center');
			console.log("Credentialed individuals results!", data)
			if (data.hasOwnProperty('Items')) {
				data.Items.forEach(function(individual) {
					addCredentialedIndividualBox(individual);
				});

				if (data.Items.length == 0) {
					container.append('<h3>No credentialed individuals could be found.</h3>');
				}

				let pagination = {
					NumRecordsFound: data.Count,
					PageNum: page + 1,
					TotalPages: Math.ceil(data.Count / perPageIndividuals),
					PerPage: perPageIndividuals
				};
				if (accrediation == "fm") {
					setFMPagination(pagination, page + 1);
				}
				else {
					setFAPagination(pagination, page + 1);
				}
				setPagesInfo(pagination, data.Items);

				Cubic.ams.ajaxRequest = 0;
			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("getCredentialedIndividuals Error!", errorThrown);

			Cubic.ams.ajaxRequest = 0;
			
			if (jqXHR.status == 429) {
				setTimeout(getCredentialedIndividuals(page, accrediation), 750);
			}
		},
		dataType: 'JSON'
	});
}

function addOrganizationBox(data) {

	let id = data.Id;
	let name = data.OrganizationName;
	let description = (data.Description) ? data.Description.substr(0, 200) : "";
	let city = (data.PrimaryAddress) ? data.PrimaryAddress.City : null;
	let stateOrCountry = (data.PrimaryAddress?.State && data.PrimaryAddress?.CountryCode == "US") ? data.PrimaryAddress.State : data.PrimaryAddress?.CountryCode;
	let joined = data.YearJoined;
	let hideIfNoDate = !data.YearJoined ? 'class="hide"' : '';
	let hideIfNoAddress = !data.PrimaryAddress ? 'class="hide"' : '';

	var template = `<div class="col-sm-6 col-xs-6 col-xxs-12 item">
						<div class="content-box">
							<h2><a href="/members/business/${id}">${name}</a></h2>
							<p>${description}</p>
							<div ${hideIfNoAddress}><em>${city}, ${stateOrCountry}</em></div>
							<div ${hideIfNoDate}><small>Member since ${joined}</small></div>
						</div>
					</div>`;

	$('#business-container').append(template);
}

function addEventRow(data) {

	let id = data.Id;
	let name = data.EventDescription;
	let slug =  _.kebabCase(data.EventDescription);
	let description = data.WebSummaryHtml || ""
	let category = data.Category || "";
	let location = data.Location || "";
	let city = data.City;
	let state = data.State;
	let startDate = Moment(data.StartDate).format("MMM DD, YYYY");
	let startTime = data.StartTime;
	let endDate = Moment(data.EndDate).format("MMM DD, YYYY");
	let endTime = data.EndTime;
	let multiDayEvent = startDate != endDate;
	let isPast = Moment().isAfter(data.EndDate);
	let isPastMessage = (isPast) ? "<p><span class='events-cancelled label label-default'>this event has passed</span></p>" : "";
	let onlineRegistration = data.OnlineRegistrationOpen;
	let displayDate = (multiDayEvent) ? startDate + " &mdash; " + endDate : startDate;
	let categoryLink = (category) ? '<p class="events-category"><a href="/events-category/' + category + '">' + category + '</a></p>' : "";
	let addToCalendar = (!isPast) ? '&nbsp;&nbsp; <a title="Add to calendar" href="/events/' + id + '/' + name + '.ics"><i class="fa fa-calendar-plus-o"></i></a>' : "";
	let registrationButton = (onlineRegistration) ? '<a class="button-blue" href="/events/' + id + '/#registration">Registration</a>' : "";
	let eventDetailsButton = '<a class="button-greenc" href="/events/' + id + '/future-fgia-events#details">Event Details</a>';

	var template = `<div class='events-entry'>
						<div class='events-date'>
							${displayDate}
							${addToCalendar}
						</div>
						<h2 class='events-title'>
							<a href='/events/${id}/'>${name}</a>
						</h2>
						${categoryLink}
						<div class='events-location'>
							${location}
						</div>
						${isPastMessage}
						<div class="event-description clearfix">
							${description}
						</div>
						${registrationButton}
						${eventDetailsButton}
						<hr>
					</div>`;

	$('#events-container').append(template);
}

function addCommitteeBox(data) {
	let id = data.Id;
	let name = data.Description;
	let date = Moment(data.DateFormed).format("MMM DD, YYYY");
	let description = (data.Scope) ? data.Scope.substr(0, 200) : "";

	var template = `<div class="col-sm-6 col-xs-6 col-xxs-12 item">
						<div class="content-box">
							<h2><a href="/committees/${id}">${name}</a></h2>
							<p>${description}</p>
							<p><small>Date established <strong>${date}</small></p>
						</div>
					</div>`;

	$('#all-committees').append(template);
}

function addCouncilBox(data) {
	let id = data.Id;
	let name = data.Description;
	let date = Moment(data.DateFormed).format("MMM DD, YYYY");
	let description = (data.Scope) ? data.Scope.substr(0, 200) : "";

	var template = `<div class="col-sm-6 col-xs-6 col-xxs-12 item">
						<div class="content-box">
							<h2><a href="/committees/${id}">${name}</a></h2>
							<p>${description}</p>
							<p><small>Date established <strong>${date}</small></p>
						</div>
					</div>`;

	$('#councils').append(template);
}

function addRegistrantBox(data) {
	let id = data.Id;
	let name = (data.MiddleInit) ? data.FirstName + " " + data.MiddleInit + " " + data.LastName : data.FirstName + " " + data.LastName;
	let organization = (data.OrganizationName) ? data.OrganizationName : "";
	let title = (data.Title) ? '<div class="title">' + data.Title + '</div>' : '';
	let cfa = (data.cfa) ? '<div class="member-certification"><strong>FAPC</strong></div>' : '';
	let cfm = (data.cfm) ? '<div class="member-certification"><strong>FMPC</strong></div>' : '';
	// Member Link <h2><a href="/members/business/member/${id}">${name}</a></h2>

	let template = `<div class="col-sm-6 col-xs-6 col-xxs-12 item">
                    	<div class="content-box">
                    		<h2>${name}</h2>
                			${organization}
                        </div>
                    </div>`;

	$('#registrants').append(template);
}

function addCredentialedIndividualBox(individual) {
	let name = (individual.MiddleInit) ? individual.FirstName + " " + individual.MiddleInit + " " + individual.LastName : individual.FirstName + " " + individual.LastName;
	let title = (individual.Title) ? ", " + individual.Title : "";
	let photo = (individual.HasPhoto) ? '<img src="' + window.Cubic.urls.AMS_PUBLIC_API + individual.IndividualPhoto + '">' : '<img src="/assets/images/member-avatar.jpg">';
	let organization = (individual.OrgWebsiteAddress) ? '<a href="' + Cubic.helpers.secureUrl(individual.OrgWebsiteAddress) + '" target="_blank">' + individual.OrgName + '</a>' : individual.OrgName;
	let bio = (individual.Biography) ? individual.Biography : "";

	let template = `<div class="row member-box margin-tb-sm">
						<div class="member-photo col-sm-3">
							${photo}
						</div>
						<div class="member-info col-sm-9">
							<strong>${name}${title}, ${organization}</strong><br />
							${bio}
						</div>
					</div>`;

	$('#credentialed-individuals').append(template);
}

function toggleLoadBar(container, message) {
    container.empty().append('<div class="text-center"><i class="fa fa-2x fa-spinner fa-pulse"></i> <h4>'+message+'</h4></div>');
    $('.pages, .pages-info').empty();
    $('html, body').animate({
        scrollTop: $(".help-block").offset().top
    }, 800, 'swing', function() {});
}

function setPagination(pagination, currentPage, contentType = 'organizations') {
	var container = $('.pages');
	container.empty();

	console.log("CALLING SET PAGINATION FUNCTION", contentType);
	console.log("PAGINATION OBJECT", pagination);

	if (pagination != undefined) {
		console.log("BUILD PAGINATION");
		if (parseInt(pagination.TotalPages) > 1) {
			$('.pages').bootpag({
				total: parseInt(pagination.TotalPages),
				page: parseInt(currentPage),
				maxVisible: 5,
				leaps: false,
				firstLastUse: true,
				first: '<i class="fa fa-angle-double-left"></i>',
				last: '<i class="fa fa-angle-double-right"></i>',
				next: '<i class="fa fa-angle-right"></i>',
				prev: '<i class="fa fa-angle-left"></i>',
				wrapClass: 'pagination pagination-sm',
				activeClass: 'active',
				disabledClass: 'disabled',
				nextClass: 'next',
				prevClass: 'prev',
				lastClass: 'last',
				firstClass: 'first'
			}).on("page", function (event, page) {
				switch (contentType) {
					case "organizations":
						getOrganizations(page - 1);
						break;
					case "committees":
						getCommittees(page - 1);
						break;
					case "events":
						getEvents(page - 1);
						break;
					case "fenestration-masters":
						getCredentialedIndividuals(page - 1, "fm");
						break;
					case "fenestration-associates":
						getCredentialedIndividuals(page - 1, "fa");
						break;
					default:
						break;
				}
			});
		}
	}
}

function setEventsPagination(pagination, currentPage) {
	setPagination(pagination, currentPage, 'events')
}

function setCommitteePagination(pagination, currentPage) {
	setPagination(pagination, currentPage, 'committees')
}

function setFMPagination(pagination, currentPage) {
	console.log("Set FM pagination!");
	setPagination(pagination, currentPage, 'fenestration-masters')
}

function setFAPagination(pagination, currentPage) {
	console.log("Set FA pagination!");
	setPagination(pagination, currentPage, 'fenestration-associates')
}

function setPagesInfo(pagination, data) {
	let perPageCount = perPage;
	var container = $('.pages-info');
	container.empty();

	if (pagination != undefined) {
		if (pagination.PerPage) perPageCount = pagination.PerPage;
		if (parseInt(pagination.NumRecordsFound) > 0) {
			var from = ((parseInt(pagination.PageNum) - 1) * perPageCount) + 1;
			var to = ((parseInt(pagination.PageNum) - 1) * perPageCount) + data.length;
			container.append('Showing results ' + from + ' to ' + to + ' of ' + pagination.NumRecordsFound);
		}
	}
}

function onChangeSearchType() {
	let searchType = $('select[name="basedon"]').val().trim();

	if (searchType == "Individual") {
		$('.keyword-search-box').hide();
		$('.individual-search-box').show();
		getOrganizationsByIndividual();
	}
	else {
		$('.keyword-search-box').show();
		$('.individual-search-box').hide();
		getOrganizations(0, false);
	}
}

function searchOrganizations(keyword, e) {
	keyword = keyword.trim().toLowerCase();
	var keycode = (e.keyCode ? e.keyCode : e.which);
	if (keycode == '13') {
		getOrganizations(0, false);
	}
}

function searchOrganizationsByIndividual(keyword, e) {
	keyword = keyword.trim().toLowerCase();
	let keycode = (e.keyCode ? e.keyCode : e.which);
	let container = $('#business-container');

	//if (keycode == '13') {
	console.log("KEYWORD", keyword)
	console.log("KEYWORD LENGTH", keyword.length)
	if (keyword.length > 2) {
		getOrganizationsByIndividual();
	}
	else {
		container.empty().removeClass('text-center');
		container.append('<h3>Please enter an individual name to search by.</h3>');
	}
}

function searchKeyUp(keyword, e) {
	keyword = keyword.trim().toLowerCase();
	if (keyword.length == 0) {
		getOrganizations(0, false);
	}
}

function searchOnEnter(keyword, e) {
    var keycode = (e.keyCode ? e.keyCode : e.which);
    if (keycode == '13') {
        getCommittees();
    }
}

function toggleEventOption(event, index) {
	var quantityField = $('#quantity_'+index);
	var checkboxInput = $(event).siblings('input[type="checkbox"]');
	var quantity = 0;

	// var maxLimit = $('input[name^="max_'+index+'"]').val();
	// maxLimit = (maxLimit) ? parseInt(maxLimit) : null;

	//var option = getOptionData(event);

	if ($(event).hasClass('disabled')) return false;

	if ($(event).hasClass('fa-square-o')) {
		//addEventOption(option, index);
		checkboxInput.prop('checked', true);
		$(event).removeClass('fa-square-o').addClass('fa-check-square-o');
		quantity = 1;
	}
	else {
		//removeEventOption(option, index);
		checkboxInput.prop('checked', false);
		$(event).removeClass('fa-check-square-o').addClass('fa-square-o');
		quantity = 0;
	}

	quantityField.val(quantity);
}

function enableOption(optionFieldId, obj) {
	let maxLimit = $(obj).closest('tr').find('input[name^="max_"]').val();
	maxLimit = (maxLimit) ? parseInt(maxLimit) : null;

	if (maxLimit && obj.value > maxLimit) obj.value = maxLimit

	if (parseInt(obj.value) > 0) {
		$('#' + optionFieldId).prop('checked',true);
		$('#' + optionFieldId).siblings('i').first().removeClass('fa-square-o').addClass('fa-check-square-o');
		return;
	}
	$('#' + optionFieldId).prop('checked',false);
	$('#' + optionFieldId).siblings('i').first().removeClass('fa-check-square-o').addClass('fa-square-o');
}

function disableOption(obj) {
	$(obj).closest('tr').find('[type="number"],[type="checkbox"]').prop("disabled", true);
	$(obj).closest('tr').find('i.fa').addClass("disabled");
}

function addToCart() {
	let error = false;
	let selectedOptions = $('input[name="eventOption"]:checked');
	let optionObject = selectedOptions.map((index, input) => {
		let optionID = input.value;
		let inputID = input.id.match(/(\d+)/)[0];
		let quantityInput = $('#quantity_'+inputID);
		let quantity = quantityInput.val();

		if (!quantityInput[0].checkValidity()) {
			quantityInput[0].reportValidity();
			error = true;
			return false;
		}

		let optionObject = {
			id : optionID,
			qty : quantity,
		};

		return optionObject;
	}).toArray();

	if (error) {
		console.log("Validation error!");
		return;
	}

	let apiData = {
		'eventId' : $('#eventId').val(),
		'eventOptions' : optionObject,
	}

	$.ajax({
		type: 'POST',
		url: '/ajax/add-to-cart',
		data: apiData,
		success: function (data, textStatus, jqXHR) {
			console.log("Add to Cart Response", data)

			if (data.success) {
				window.location.href = "/cart";
			}
			else {
				console.log(data.error);
				let error = errorTemplate(data.error);

				$(".message-error").append(error);
				$('html, body').animate({
					scrollTop: $(".message-error").offset().top
				}, 800);
			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("Add to Cart Error!", errorThrown);
		},
		dataType: 'JSON'
	})
}

function removeFromCart(optionID) {
	console.log("Removing option " + optionID + " from cart!");

	$.ajax({
		type: 'POST',
		url: '/ajax/remove-from-cart',
		data: { eventOption: optionID },
		success: function (data, textStatus, jqXHR) {
			console.log("Remove From Cart Response", data)

			if (data.success) {
				window.location.href = "/cart";
			}
			else {
				console.log(data.error);
			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("Remove From Cart Error!", errorThrown);
		},
		dataType: 'JSON'
	})
}

function removeFromGuestCart(guestID, optionID) {
	console.log("Removing guest option " + optionID + " from cart!");

	$.ajax({
		type: 'POST',
		url: '/ajax/remove-from-guest-cart',
		data: { guest: guestID, eventOption: optionID },
		success: function (data, textStatus, jqXHR) {
			console.log("Remove From Guest Cart Response", data)

			if (data.success) {
				window.location.href = "/cart";
			}
			else {
				console.log(data.error);
			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("Remove From Cart Error!", errorThrown);
		},
		dataType: 'JSON'
	})
}

function saveReturnUrl() {
	console.log("Calling SAVE URL function");
	let path = window.location.pathname;
	if (path == "/session/logout/") {
		path = "/";
	}

	$.ajax({
		type: 'POST',
		url: '/ajax/save-return-url',
		data: { url : path },
		success: function (data, textStatus, jqXHR) {
			console.log("SAVED RETURN URL!", data)
		}
	})
}

function openPaymentForm(e) {
	e.preventDefault();
	$('#AcceptUIContainer').css('position','fixed');

	return false;
}

// function savePaymentForm(response) {
// 	console.log("Saving payment form!");

// 	if (response.messages.resultCode === "Error") {
// 		if (response.messages.message !== "undefined" && response.messages.message.length) {
// 			response.messages.message.forEach((message) => {
// 				console.log(message.code + ": " + message.text);
// 			})
// 		}
// 	}

// 	$("#dataDescriptor").val(opaqueData.dataDescriptor);
//     $("#dataValue").val(opaqueData.dataValue);
// }

function validateRegistration() {
	var dataDescriptor = $('#dataDescriptor').val();
	var dataValue = $('#dataValue').val();
	var safetyAgreement = ($('#safetyAgree').length) ? $('#safetyAgree').prop('checked') == true : true;

	// Check if AJAX request currently being processed
	if (Cubic.ams.ajaxRequest != 0) return;

	// Check if user accepted the safety agreement, when applicable
	if (!safetyAgreement) {
		appendValidationError("Please accept the safety agreement to proceed with the registration.");
		$('html, body').animate({
			scrollTop: $("#safetyAgree").offset().top
		}, 800);
		return;
	}

	// Check if payment information was provided
	if (!dataDescriptor || !dataValue) {
		appendValidationError("Please enter and save your payment information to proceed with the registration.");
		$('html, body').animate({
			scrollTop: $(".checkout-title").offset().top
		}, 800);
		return;
	}

	Cubic.ams.ajaxRequest = 1;

	$('#placeorder').prop('disabled',true).html('<i class="fa fa-spinner fa-pulse"></i> Processing ...');

	$.ajax({
		type: 'POST',
		url: 'ajax/validate-registration',
		data: {
			firstName: $('#firstName').val(),
			lastName: $('#lastName').val(),
			email: $('#emailAddress').val(),
			address1: $('#billAddress1').val(),
			address2: $('#billAddress2').val(),
			city: $('#billCity').val(),
			state: $('#billState').val(),
			zip: $('#billZip').val(),
			dataDescriptor: dataDescriptor,
			dataValue: dataValue,
		},
		success: function (data, textStatus, jqXHR) {
			Cubic.ams.ajaxRequest = 0;

			console.log("Validate registration response!", data)

			if (data?.response?.Errors.length) {
				data.response.Errors.forEach((error) => {
					var template = `<div class="error">
										<div class="alert alert-danger alert-dismissible" role="alert">
											<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
											${error.Property}: ${error.Message}
										</div>
									</div>`;

					$("#validationErrors").append(template);
				});

				$('#placeorder').prop('disabled',false).html('Place Order');
				$('html, body').animate({
					scrollTop: $(".checkout-title").offset().top
				}, 800);
			}

			if (data?.response?.Success) {
				submitRegistration(data.response);
			}

		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("Validate Registration Error!", jqXHR, textStatus, errorThrown);

			Cubic.ams.ajaxRequest = 0;

			var template = `<div class="error">
								<div class="alert alert-danger alert-dismissible" role="alert">
									<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
									Server error encountered submitting event!
								</div>
							</div>`;

			$("#validationErrors").append(template);

			$('#placeorder').prop('disabled',false).html('Place Order');
			$('html, body').animate({
				scrollTop: $(".checkout-title").offset().top
			}, 800);
		},
		dataType: 'JSON'
	});

}

function appendValidationError(message) {
	var template = `<div class="error">
						<div class="alert alert-danger alert-dismissible" role="alert">
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							${message}
						</div>
					</div>`;

	$("#validationErrors").append(template);
}

function submitRegistration(registrationData) {
	console.log("Submitting registration!");

	$.ajax({
		type: 'POST',
		url: 'ajax/submit-registration',
		data: {
			firstName: registrationData.CreditCardInfo.BillingFirstName,
			lastName: registrationData.CreditCardInfo.BillingLastName,
			email: registrationData.CreditCardInfo.BillingEmail,
			address1: registrationData.CreditCardInfo.BillingAddress,
			address2: registrationData.CreditCardInfo.BillingAddress2,
			city: registrationData.CreditCardInfo.BillingCity,
			state: registrationData.CreditCardInfo.BillingState,
			zip: registrationData.CreditCardInfo.BillingZip,
			dataDescriptor: registrationData.CreditCardOpaqueData.DataDescriptor,
			dataValue: registrationData.CreditCardOpaqueData.DataValue,
		},
		success: function (data, textStatus, jqXHR) {
			console.log("Submit registration response!", data)

			if (data?.response?.Errors.length) {
				Cubic.ams.ajaxRequest = 0;
				data.response.Errors.forEach((error) => {
					var template = `<div class="error">
										<div class="alert alert-danger alert-dismissible" role="alert">
											<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
											${error.Property}: ${error.Message}
										</div>
									</div>`;

					$("#validationErrors").append(template);

					$('#placeorder').prop('disabled',false).html('Place Order');
					$('html, body').animate({
						scrollTop: $(".checkout-title").offset().top
					}, 800);
				});
			}

			if (data?.response?.Success) {
				sendReceipt(data.response);
				$.post('/ajax/delete-cart'); // Clear the cart
			}

		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("Submit Registration Error!", jqXHR, textStatus, errorThrown);

			Cubic.ams.ajaxRequest = 0;

			var template = `<div class="error">
								<div class="alert alert-danger alert-dismissible" role="alert">
									<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
									Server error encountered submitting event!
								</div>
							</div>`;

			$("#validationErrors").append(template);

			$('#placeorder').prop('disabled',false).html('Place Order');
			$('html, body').animate({
				scrollTop: $(".checkout-title").offset().top
			}, 800);
		},
		dataType: 'JSON'
	});
}

function sendReceipt(registrationData) {
	console.log("Sending receipt!");

	// Send receipt email
	var cartJSON = JSON.parse($('.cartJSON').val());
	var cartSubtotal = $('.cartSubtotal').val();
	var cartTotal = $('.cartTotal').val();

	console.log("REGISTRATION DATA", registrationData);

	$.ajax({
        type: 'POST',
        url: '/ajax/send-receipt',
        data: { order: registrationData, cart: cartJSON, subtotal: cartSubtotal, total: cartTotal},
        success: function (data, textStatus, jqXHR) {

            if (data.response.success == true) {
                $('.checkout-content').append('<div style="width:100%;" class="alert alert-success alert-dismissible" role="alert">Registration receipt sent successfully!</div>');
            } else {
                $('.checkout-content').append('<div style="width:100%;" class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><strong>Error!</strong> The receipt could not be sent at this time.</div>');
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            $('.checkout-content').append('<div style="width:100%;" class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><strong>Error!</strong> The receipt could not be sent at this time.</div>');
        },
        dataType: 'JSON'
	});

	$('#detailReceipt').slideDown();
	$('.checkout-content').empty();
	$('.checkout-title').empty();
	var div_content = $('<div>',{'class':'col-md-12 col-sm-12 col-xs-12'}).wrap('<div class="row"></div>');
	div_content.append($('<h1>').append('Thank You!'));
	div_content.append($('<p>').append('<br />Dear <strong>' + registrationData.CreditCardInfo.BillingFirstName + '</strong>, <br />We appreciate your recent purchase. Visit our <a href="/events">events page</a> to view future events or to register for another event.'));
	$('.checkout-content').append(div_content.parent());
}

function addGuest() {
	console.log("Adding guest!");

	var form = $('#guestForm').get(0);

	if(form.checkValidity()) {
		var $submitButton = $(form).find('button[type="submit"]')
            $submitButton.prop('disabled',true).html('<i class="fa fa-spinner fa-pulse"></i> Saving...')
		$.ajax({
			type: "POST",
			url: "/ajax/add-guest",
			data: {
				mainregoption: $('input[name="mainregoption"]').val(),
				fname: $(form).find('input[name="fname"]').val(),
				lname: $(form).find('input[name="lname"]').val(),
				middleinit: $(form).find('input[name="middleinit"]').val(),
				company: $(form).find('input[name="company"]').val(),
				title: $(form).find('input[name="title"]').val(),
				pname: $(form).find('input[name="pname"]').val(),
				salutation: $(form).find('input[name="salutation"]').val(),
				suffix: $(form).find('input[name="suffix"]').val(),
				email: $(form).find('input[name="email"]').val(),
				relationship: $(form).find('select[name="relationship"]').val(),
			},
			success: function(data, textStatus, jqXHR) {

				if ( data.success ) {
					$('.notification').empty().append('<div style="width:100%;" class="alert alert-success alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>The guest information was updated successfully.</div>');
					$submitButton.prop('disabled',false).html('Submit');
					setTimeout(function() { window.location.href = '/cart'; }, 3500);
				} else {
					console.log("ERROR! ", data.message);
					$('.notification').empty().append('<div style="width:100%;" class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><strong>Error!</strong> The guest information could not be updated at this moment. Please try again later.</div>');
					$submitButton.prop('disabled',false).html('Submit');
				}
			},
			dataType: 'JSON'
		});
	}
	else {
		console.log("Form errors!")
	}
}

function selectGuest() {
	console.log("Selecting guest!");

	$.ajax({
		type: "POST",
		url: "/ajax/select-guest",
		data: {
			id: $('select[name="selectedIndividual"]').val(),
			mainregoption: $('input[name="mainregoption"]').val(),
		},
		success: function(data, textStatus, jqXHR) {

			if (data.success) {
				window.location.href = '/cart';
			} else {
				console.log("ERROR! ", data.message);
			}
		},
		dataType: 'JSON'
	});
}

function removeGuest(guestId) {
	console.log("Removing guest with ID of " + guestId);

	$.ajax({
		type: "POST",
		url: "/ajax/remove-guest",
		data: {
			id: guestId,
		},
		success: function(data, textStatus, jqXHR) {

			if (data.success) {
				window.location.href = '/cart';
			} else {
				console.log("ERROR! ", data.message);
			}
		},
		dataType: 'JSON'
	});
}

function resetGuestForm() {
	console.log("Resetting guest form!");
}

function addToGuest() {
	let selectedOptions = $('input[name="eventOption"]:checked');
	let optionObject = selectedOptions.map((index, input) => {
		let optionID = input.value;
		let inputID = input.id.match(/(\d+)/)[0];
		let quantity = $('#quantity_'+inputID).val();

		let optionObject = {
			id : optionID,
			qty : quantity,
		};

		return optionObject;
	}).toArray();

	let apiData = {
		'guestId' : $('#guestId').val(),
		'guestOptions' : optionObject,
	}

	$.ajax({
		type: 'POST',
		url: '/ajax/add-to-guest',
		data: apiData,
		success: function (data, textStatus, jqXHR) {
			console.log("Add to Guest Response", data)

			if (data.success) {
				window.location.href = "/cart";
			}
			else {
				console.log(data.error);
			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("Add to Guest Error!", errorThrown);
		},
		dataType: 'JSON'
	})
}

function backToEvent() {
	let currentEventID = $('#eventId').val();

	window.location.href = '/events/'+currentEventID;
}

function clearCart() {
	console.log("Clearing cart!");

	$.ajax({
		type: 'POST',
		url: '/ajax/delete-cart',
		success: function (data, textStatus, jqXHR) {
			console.log("Delete Cart Response", data)
			window.location.href = "/cart";
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("Delete Cart Error!", errorThrown);
		},
		dataType: 'JSON'
	})
}

function saveProfile() {
	console.log("Saving user profile");

	var form = $('#member-form').get(0);

	if(form.checkValidity()) {
		var $submitButton = $(form).find('#btn_submit');
        $submitButton.prop('disabled',true).html('<i class="fa fa-spinner fa-pulse"></i> Saving...');

		$.ajax({
			type: "POST",
			url: "/ajax/profile-update",
			data: {
				salutation: $(form).find('input[name="Salutation"]').val(),
				firstname: $(form).find('input[name="FirstName"]').val(),
				lastname: $(form).find('input[name="LastName"]').val(),
				middleinit: $(form).find('input[name="MiddleInitial"]').val(),
				preferredname: $(form).find('input[name="PreferredName"]').val(),
				title: $(form).find('input[name="Title"]').val(),
				address1: $(form).find('input[name="Address1"]').val(),
				address2: $(form).find('input[name="Address2"]').val(),
				city: $(form).find('input[name="City"]').val(),
				state: $(form).find('select[name="State"]').val(),
				zip: $(form).find('input[name="Zip"]').val(),
				country: $(form).find('select[name="Country"]').val(),
				phone: $(form).find('input[name="Phone1"]').val(),
				extension: $(form).find('input[name="Extension1"]').val(),
				altphone: $(form).find('input[name="Phone2"]').val(),
				altextension: $(form).find('input[name="Extension2"]').val(),
				fax: $(form).find('input[name="Fax"]').val(),
				email: $(form).find('input[name="Email_Address"]').val(),
				facebook: $(form).find('input[name="facebook"]').val(),
				linkedin: $(form).find('input[name="linkedin"]').val(),
				twitter: $(form).find('input[name="twitter"]').val(),
				youtube: $(form).find('input[name="youtube"]').val(),
			},
			success: function(data, textStatus, jqXHR) {

				if ( data.Success ) {
					$('#alert-success').show();
					window.location.href = '#alert-success';
					$submitButton.prop('disabled',false).html('Save');
				} else {
					console.log("ERROR! ", data.message);
					$('#alert-danger').show();
					window.location.href = '#alert-danger';
					$submitButton.prop('disabled',false).html('Save');
				}
			},
			dataType: 'JSON'
		});
	}
	else {
		console.log("Form errors!")
	}
}

function resetProfile(e) {
	e.preventDefault();

	var choice = confirm("Are you sure you want to cancel your changes?");

	if(choice == true) {
		$('#member-form')[0].reset();
		$('#alert-reset').show();
		$('html, body').animate({
			scrollTop: $("#alert-reset").offset().top
		}, 800);
	}
}

function saveBusiness(e) {
	console.log("Saving business profile")
	e.preventDefault();

	var form = $('#member-form').get(0);
	var updatedReps = getUpdatedReps();
	var newReps = getNewReps();

	console.log("UPDATED REPS", updatedReps)
	console.log("NEW REPS", newReps)

	if(form.checkValidity()) {
		var $submitButton = $(form).find('#btn_submit');
        $submitButton.prop('disabled',true).html('<i class="fa fa-spinner fa-pulse"></i> Saving...');

		$.ajax({
			type: "POST",
			url: "/ajax/business-update",
			data: {
				name: $(form).find('input[name="Name"]').val(),
				dba: $(form).find('input[name="DBA"]').val(),
				description: $(form).find('input[name="BusinessDescription"]').val(),
				address1: $(form).find('input[name="Address1"]').val(),
				address2: $(form).find('input[name="Address2"]').val(),
				city: $(form).find('input[name="City"]').val(),
				state: $(form).find('select[name="State"]').val(),
				zip: $(form).find('input[name="Zip"]').val(),
				country: $(form).find('select[name="Country"]').val(),
				phone: $(form).find('input[name="Phone1"]').val(),
				extension: $(form).find('input[name="Country_Code"]').val(),
				altphone: $(form).find('input[name="Phone2"]').val(),
				altextension: $(form).find('input[name="Extension2"]').val(),
				fax: $(form).find('input[name="Fax"]').val(),
				email: $(form).find('input[name="Email_Address"]').val(),
				website: $(form).find('input[name="Website"]').val(),
				facebook: $(form).find('input[name="facebook"]').val(),
				linkedin: $(form).find('input[name="linkedin"]').val(),
				twitter: $(form).find('input[name="twitter"]').val(),
				youtube: $(form).find('input[name="youtube"]').val(),
				updatedReps: updatedReps,
				newReps: newReps
			},
			success: function(data, textStatus, jqXHR) {

				if ( !data.error ) {
					$('.new-reps').empty();
					$('.user-error-content').empty();
					$('#alert-error, #alert-success, #alert-danger, #alert-reset').hide();
					$('#alert-success').show();
					window.location.href = '#alert-success';
					$submitButton.prop('disabled',false).html('Save');
				} else {
					if (!data.businessUpdate.Success) {
						console.log("ERROR! ", data.businessUpdate.Errors);
						//$('.user-error-content').empty().append(data.businessUpdate.Errors);
					}

					if (!data.repUpdate.Success) {
						console.log("ERROR! ", data.repUpdate.Errors);
					}
					$('#alert-danger').show();
					window.location.href = '#alert-danger';
					$submitButton.prop('disabled',false).html('Save');
				}
			},
			dataType: 'JSON'
		});
	}
	else {
		console.log("Form errors!")
	}
}

function getUpdatedReps() {
	var repArray = [];
	var $representatives = $('.representative');
	$.each($representatives, function(index, rep) {
		if($(rep).hasClass('mainRep')) return;

		var email = $(rep).find('.rep_email').val();
		var repStatus = $(rep).find('.rep_action:checked').val();
		var initialState = $(rep).find('.repInitialState').val();
		var initialEmail = $(rep).find('.repInitialEmail').val();

		if(repStatus == "Active" && !initialState == "Active") {
			repStatus = "Active";
		}
		else if(repStatus == "Inactive" && initialState != "Inactive") {
			repStatus = "Inactive";
		}
		else if(repStatus == "Delete") {
			repStatus = "Delete";
		}
		else {
			repStatus = false;
		}

		if(repStatus != false || initialEmail != email) {
			repArray.push({
				id: $(rep).find('.individualID').val(),
				name: $(rep).find('.fullName').val(),
				email: $(rep).find('.rep_email').val(),
				status: repStatus || initialState
			});
		}
	});

	return repArray;
}

function getNewReps() {
	var newRepsArray = [];
	var $newReps = $('.new-reps .new-rep');
	$.each($newReps, function(index, rep) {
		newRepsArray.push({
			fname: $(rep).find('[name="fname"]').val(),
			lname: $(rep).find('[name="lname"]').val(),
			email: $(rep).find('[name="email"]').val(),
		});
	});

	return newRepsArray;
}

function addRep(e) {
	e.preventDefault();

	var $template = $('.rep-template');
	var $repSection = $('.add-reps-section');
	var $newReps = $('.new-reps');

	$newReps.append($template.clone().html()).show();
	$repSection.slideDown();
}

function resetBusinessProfile(e) {
	e.preventDefault();

	var choice = confirm("Are you sure you want to cancel your changes?");

	if(choice == true) {
		$('#member-form')[0].reset();
		$('.new-reps').empty();
		$('#alert-reset').show();
		$('html, body').animate({
			scrollTop: $("#alert-reset").offset().top
		}, 800);
	}
}

function downloadMinuteReport(e, filename, fileType, reportUrl) {
	e.preventDefault();

	let cleanFileName = filename.trim().replace(/[^\w ]/g, '') + "." + fileType;

	$.ajax({
		type: "POST",
		url: "/ajax/download-report",
		data: {
			url: reportUrl,
			type: fileType,
		},
		success: function(response, textStatus, jqXHR) {

			if (response.success) {
				const data = Uint8Array.from(response.file.data);
				const content = new Blob([data.buffer], { type: response.contentType });

				const encodedUri = window.URL.createObjectURL(content);
				const link = document.createElement("a");

				link.setAttribute("href", encodedUri);
				link.setAttribute("download", cleanFileName);

				link.click();
			} else {
				console.log("ERROR! ", response.message);
			}
		},
		dataType: 'JSON'
	});
}

function errorTemplate(errorMessage) {
	let template = `<div class="error">
		<div class="alert alert-danger alert-dismissible" role="alert">
			<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
				${errorMessage}
		</div>
	</div>`;

	return template;
}
